@font-face {
font-family: 'effra';
src: url(/_next/static/media/5ed96d4f56ad5d0c-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'effra Fallback';src: local("Arial");ascent-override: 96.60%;descent-override: 27.07%;line-gap-override: 0.00%;size-adjust: 96.06%
}.__className_a35b51 {font-family: 'effra', 'effra Fallback'
}.__variable_a35b51 {--font-families-effra: 'effra', 'effra Fallback'
}

@font-face {
font-family: 'lyon';
src: url(/_next/static/media/bcd52df79c66ea16-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'lyon';
src: url(/_next/static/media/fb7f8282599dfd18-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'lyon Fallback';src: local("Arial");ascent-override: 100.50%;descent-override: 28.02%;line-gap-override: 0.00%;size-adjust: 93.13%
}.__className_cde353 {font-family: 'lyon', 'lyon Fallback'
}.__variable_cde353 {--font-families-lyon: 'lyon', 'lyon Fallback'
}

